//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchEquComList } from "@/api/charge";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "communicList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        start_time: "",
        end_time: "",
        time: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      pickerOptions: _global.timeOption
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchEquComList(data).then(function (response) {
        console.log(response);
        _this.tableLoading = false;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
      }).catch(function (err) {
        console.log(err);
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    }
  }
};